import * as React from "react";
import styles from "./styles/renderForm.module.scss";

import { TextField } from 'office-ui-fabric-react/lib/TextField';
import { IRenderFormProps } from "./interfaces/IRenderForm";
import { ControlMode } from "./interfaces/ControlMode";

import { Icon } from 'office-ui-fabric-react/lib/Icon';

import { formFieldsList } from "./formFields/formFieldsList"

import DraggableComponent from "./draggable/DraggableComponent";

import { DragDropContext, Droppable } from "react-beautiful-dnd";

import Store from "../../store/store";

export default class RenderForm extends React.Component<IRenderFormProps, any> {
  constructor(props: IRenderFormProps) {
    super(props);
    this.state = {
    };
  }
  render() {
    return (
      <div className={styles.renderFormContainer}>
        <React.Fragment>
          {
            this.props.controlMode == ControlMode.Edit ?
              <React.Fragment>
                <TextField
                  onBlur={this.props.formInfo.id != undefined && this.props.formInfo.id != null ? this.props.saveFormInfo : () => {}}
                  onChange={this.props.onChangeFormInfo("title")}
                  required
                  defaultValue={this.props.formInfo.name}
                  label=""
                  borderless
                  placeholder="Titre du formulaire"
                  className={styles.titleFormInput}
                  maxLength={255}
                />
                <TextField
                  onBlur={this.props.formInfo.id != undefined && this.props.formInfo.id != null ? this.props.saveFormInfo : () => {}}
                  onChange={this.props.onChangeFormInfo("description")}
                  label=""
                  borderless
                  multiline
                  rows={2}
                  placeholder="Description"
                  defaultValue={this.props.formInfo.description}
                />
              </React.Fragment>
              :
              <React.Fragment>
                <h2>{this.props.formInfo.name}</h2>
                <p>{this.props.formInfo.description}</p>
              </React.Fragment>
          }
        </React.Fragment>

        {
          this.props.fieldsInfo.length > 0 ?
            <DragDropContext onDragEnd={this.props.moveQuestion}>
              <Droppable droppableId="noGroupQuestions">
                {(provided:any, snapshot:any) => (
                  <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                  // style={getListStyle(snapshot.isDraggingOver)}
                  >
                    {
                    this.props.fieldsInfo.map((field: any, idx: number) => {
                        const FieldComponent = formFieldsList[field.createData.TypeAsString];
                        if(FieldComponent != undefined && FieldComponent != null) {
                          if (this.props.controlMode == ControlMode.Edit) {
                            const fieldValue = field.createData.DefaultValue != undefined && field.createData.DefaultValue != null ? field.createData.DefaultValue : "";
                            return (
                              <DraggableComponent
                                key={field.id + "/" + this.props.updated}
                                index={idx}
                                itemKey={field.id.toString()}
                                question={field}
                                deleteQuestion={this.props.deleteQuestion}
                                openPanel={this.props.openPanel}
                                columnSystemStartWith={this.props.columnSystemStartWith}
                                formStatus={this.props.formInfo.status}
                              >
                                <div style={{position:"relative"}}>
                                  <FieldComponent field={field} value={fieldValue} />
                                  <div style={{position: "absolute",top: 0,left: 0,width: "100%",height: "100%"}}></div>
                                </div>
                              </DraggableComponent>
                            )
                          }
                          else if (this.props.controlMode == ControlMode.New) {
                            const fieldValue = this.props.dataSharepoint[field.internalName] != undefined && this.props.dataSharepoint[field.internalName] != null ? this.props.dataSharepoint[field.internalName] : "";
                            if(field.internalName != undefined && field.internalName != null && field.internalName.indexOf("lyform_") == 0) {
                              return <FieldComponent key={field.id != undefined && field.id != null ? field.id : field.internalName} field={field} value={fieldValue} onChange={this.props.onChangeValue} readOnly={true} />
                            }
                            else {
                              return <FieldComponent key={field.id != undefined && field.id != null ? field.id : field.internalName} field={field} value={fieldValue} onChange={this.props.onChangeValue} />
                            }
                          }
                          else {
                            if(field.internalName != undefined && field.internalName != null && this.props.dataSharepoint != undefined && this.props.dataSharepoint != null){
                              const value = this.props.dataSharepoint[field.internalName];
                              return <FieldComponent key={field.id != undefined && field.id != null ? field.id : field.internalName} field={field} value={value} readOnly={true} />
                            }
                            else {
                              return <FieldComponent key={field.id != undefined && field.id != null ? field.id : field.internalName} field={field} value="" readOnly={true}/>
                            }
                            
                          }
                        }
                        else {
                          return (<React.Fragment></React.Fragment>);
                        }
                      })
                    }
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
            :
            <React.Fragment></React.Fragment>
        }


        {
          this.props.controlMode == ControlMode.Edit && Store.userConnected.formRights == "Admin" &&
          <div onClick={this.props.openPanel("add")} className={styles.addFieldToolbox}>
            <div className={styles.addFieldToolboxPlusButton}>
              <Icon title="Ajouter une question" iconName='Add' />
            </div>
          </div>
        }
      </div>
    );
  }
}
import * as React from "react";
import styles from "./styles/ficheDePoste.module.scss";
import RenderForm from "../renderForm/RenderForm";
import Store from "../../store/store";
import { ControlMode } from "../renderForm/interfaces/ControlMode";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { IFormInfo, IColumnInfo } from "../renderForm/interfaces/IRenderForm";
import { useQuery } from "../../helper/helpers";
import axios from "axios";
import { SpinnerSize, Spinner } from "office-ui-fabric-react/lib/Spinner";
import { PrimaryButton, DefaultButton } from "office-ui-fabric-react/lib/Button";
import { DialogType, DialogFooter, Dialog } from "office-ui-fabric-react/lib/Dialog";

export interface IFicheDePoste {
  id?: number;
  idForm?: number;
  form_Form?: IFormInfo;
  data?: any;
  fdpFields?: IColumnInfo[];
};

interface IFicheDePosteProps {

};

interface IFicheDePosteState {
  formInfo: IFormInfo;
  ficheDePoste: any;
  loading: boolean;
  loadingSaveButton: boolean;
  error: boolean;
  errorMessage: string;
  ficheDePosteFields: IColumnInfo[];
  deleteFDPModal: {
    hideDialog: boolean;
    error: boolean;
    errorMessage: string;
    loading: boolean;
  }
};

// Fonction appelée pour créer une fiche de poste
export const createFicheDePoste = (idForm: number) => {
  return new Promise<IFicheDePoste>(async (resolve, reject) => {
    try {
      const result = await axios.post(Store.wsPath + "/1/formulaire/CreateFicheDePoste", {
        idForm: idForm
      });
      const ficheDePoste: IFicheDePoste = result.data;
      resolve(ficheDePoste);
    } catch (error) {
      console.error("Error 'createFicheDePoste' : ", error);
      reject("Error 'createFicheDePoste' : " + error);
    }
  });
}

class FicheDePoste extends React.Component<IFicheDePosteProps & RouteComponentProps, IFicheDePosteState> {
  constructor(props: IFicheDePosteProps & RouteComponentProps) {
    super(props);
    this.state = {
      loading: true,
      loadingSaveButton: false,
      error: false,
      errorMessage: "",
      ficheDePosteFields: [],
      formInfo: {
        name: "",
        description: "",
        formType: "lyform",
        form_Columns: []
      },
      ficheDePoste: null,
      deleteFDPModal: {
        hideDialog: true,
        error: false,
        errorMessage: "",
        loading: false,
      }
    };
  }

  public async componentDidMount() {
    let errorMessage = "Une erreur est survenue lors de la récupération de la fiche de poste";
    try {
      // Récupération de l'id du form dans l'url
      const query = useQuery();
      const idForm: number = parseInt(query.get('idForm'));

      // Si il y a un id on récupére la fiche de poste liée au form
      if (!isNaN(idForm)) {
        // Récupération du formulaire
        errorMessage = "Une erreur est survenue lors de la récupération de la fiche de poste";

        // On get la fiche de poste, les fields (colonnes) de la fiche de poste et le form liai à la fiche de poste
        const ficheDePosteWithAllInfo: IFicheDePoste = await this.getFicheDePosteInfo(idForm);

        if (ficheDePosteWithAllInfo != undefined && ficheDePosteWithAllInfo != null && ficheDePosteWithAllInfo.id != undefined) {
          if (ficheDePosteWithAllInfo.form_Form != undefined && ficheDePosteWithAllInfo.form_Form != null) {
            ficheDePosteWithAllInfo.form_Form["description"] = "Fiche de poste associée au formulaire '" + ficheDePosteWithAllInfo.form_Form.name + "'.";
          }
          // ficheDePosteWithAllInfo["form_Form"]["name"] = "Fiche de Poste"
          this.setState({ formInfo: ficheDePosteWithAllInfo.form_Form, ficheDePoste: ficheDePosteWithAllInfo, ficheDePosteFields: ficheDePosteWithAllInfo.fdpFields, loading: false, error: false, errorMessage: "" });
        }
        else {
          errorMessage = "La fiche de poste associée au formulaire '" + idForm + "' n'a pas été trouvée";
          throw new Error("La fiche de poste associée au formulaire '" + idForm + "' n'a pas été trouvée");
        }
      }
      else {
        errorMessage = "Pas d'id formulaire présent dans l'url";
        throw new Error("Pas d'id formulaire présent dans l'url");
      }
    } catch (error) {
      console.error("Error 'getFicheDePoste' : ", error);
      Store.snackBar = { error: true, message: errorMessage, open: true };
      this.setState({ loading: false, error: true, errorMessage: errorMessage });
    }
  }

  // Fonction pour récupérer la fiche de poste d'un formulaire en base de données
  public getFicheDePosteInfo = (idForm: number) => {
    return new Promise<IFicheDePoste>(async (resolve, reject) => {
      try {
        const result = await axios.get(Store.wsPath + "/1/formulaire/GetFicheDePosteByIdForm", {
          params: {
            idForm: idForm
          }
        });
        const fields: IFicheDePoste = result.data;
        resolve(fields);
      } catch (error) {
        console.error("Error 'getFicheDePosteFields' : ", error);
        reject("Error 'getFicheDePosteFields' : " + error);
      }
    });
  }

  // Fonction appelée dans les onChange des champs
  public onChangeValue = (question: IColumnInfo, type?: string) => (event: any, newValue?: any, index?: number, value?: any) => {
    const formData = this.state.ficheDePoste.data != undefined && this.state.ficheDePoste.data != null ? { ...this.state.ficheDePoste.data } : {};
    // const files = { ...this.state.PJS };
    switch (question.createData.TypeAsString) {
      case "Text":
      case "Note":
      case "Number":
      case "Boolean":
        formData[question.internalName] = newValue;
        break;

      case "Choice":
        if (question.createData.isMulti == true) {
          if (formData[question.internalName] != undefined && formData[question.internalName] != null) {
            if (newValue.selected == true) {
              formData[question.internalName] = [...formData[question.internalName], newValue.key];
            }
            else {
              formData[question.internalName] = [...formData[question.internalName].filter((ch: string) => ch != newValue.key)];
            }
          }
          else {
            if (newValue.selected == true) {
              formData[question.internalName] = [newValue.key];
            }
            else {
              formData[question.internalName] = [...formData[question.internalName].filter((ch: string) => ch != newValue.key)];
            }
          }
        }
        else {
          formData[question.internalName] = newValue.key;
        }
        break;

      case "DateTime":
        if (type == "hour") {
          if (newValue.length == 5) {
            const splitHM = newValue.split(":");
            const heures = splitHM[0];
            const minutes = splitHM[1];
            const dateValue = formData[question.internalName];
            const date = new Date(dateValue);
            date.setHours(parseInt(heures), parseInt(minutes), 0);

            const dateWithoutUTC = date.toString().split(" GMT")[0];
            formData[question.internalName] = dateWithoutUTC;
          }
        }
        else {
          const dateString: string = event.toString();
          const dateWithoutUTC = dateString.split(" GMT")[0];

          formData[question.internalName] = dateWithoutUTC;
        }
        break;

      // case "File": {
      //   if (event.target.files.length > 0) {
      //     const file = event.target.files[0];
      //     files[question.createData.Title] = file;
      //   }
      //   else {
      //     delete files[question.createData.Title];
      //   }
      //   break;
      // }

      default:
        break;
    }
    this.setState({ ficheDePoste: { ...this.state.ficheDePoste, data: formData } });
  }

  // Fonction appelée pour sauvegarder la fiche de poste en base de donnéees
  public saveFDPData = (event) => {
    event.preventDefault();
    this.setState({ loadingSaveButton: true }, async () => {
      try {
        await axios.post(Store.wsPath + "/1/formulaire/SaveFicheDePoste", {
          data: this.state.ficheDePoste.data,
          id: this.state.ficheDePoste.id
        });
        Store.snackBar = { error: false, message: "Sauvegarde de la fiche de poste réussie", open: true };
        this.setState({ loadingSaveButton: false });
      } catch (error) {
        console.error("Error 'saveFDPData' : ", error);
        Store.snackBar = { error: true, message: "Erreur lors de la sauvegarde de la fiche de poste, réessayez", open: true };
        this.setState({ loading: false, loadingSaveButton: false });
      }
    });
  }

  public openCloseDeleteDialog = (event) => {
    this.setState({ deleteFDPModal: { ...this.state.deleteFDPModal, hideDialog: !this.state.deleteFDPModal.hideDialog, error: false, errorMessage: "" } });
  }

  public deleteFDP = (event) => {
    this.setState({ deleteFDPModal: { ...this.state.deleteFDPModal, loading: true } }, async () => {
      try {
        await axios.post(Store.wsPath + "/1/formulaire/DeleteFicheDePoste", {
          id: this.state.ficheDePoste.id
        });
        Store.snackBar = { error: false, message: "Fiche de poste supprimée", open: true };
        this.props.history.push("/forms");
      } catch (error) {
        console.error("Error 'deleteFDP' : ", error);
        this.setState({ deleteFDPModal: { ...this.state.deleteFDPModal, loading: false, error: true, errorMessage: "Une erreur est survenue lors de la suppression de la fiche de poste" } });
      }
    });
  }

  render() {
    return (
      <div className={"mainContent"}>
        <div className={"titleComponent"} style={{ background: Store.themeParam.backgroundPrimary }}><h5>Fiche de Poste :</h5></div>
        <div className={"contentComponent"}>
          <DefaultButton iconProps={{ iconName: 'Back' }} onClick={() => this.props.history.push("/forms")} text="Retour" />
          <div className={styles.ficheDePosteContainer}>
            {this.state.error == true ?
              <p>{this.state.errorMessage}</p>
              :
              this.state.loading == true ?
                <Spinner size={SpinnerSize.large} label="Récupération de la fiche de poste..." labelPosition={"bottom"} />
                :
                <form id={'formInfo' + this.state.formInfo.id} onSubmit={this.saveFDPData}>
                  <RenderForm
                    formInfo={this.state.formInfo}
                    controlMode={Store.userConnected.formRights != "Lecture" ? ControlMode.New : ControlMode.Display}
                    fieldsInfo={this.state.ficheDePosteFields != undefined && this.state.ficheDePosteFields != null && this.state.ficheDePosteFields.length > 0 ? [...this.state.ficheDePosteFields] : []}
                    onChangeValue={this.onChangeValue}
                    dataSharepoint={this.state.ficheDePoste.data}
                  />

                  {
                    Store.userConnected.formRights != "Lecture" ?
                      <div className={styles.buttonContainer}>
                        <DefaultButton disabled={this.state.loadingSaveButton} onClick={this.openCloseDeleteDialog} text="Supprimer" />
                        <PrimaryButton disabled={this.state.loadingSaveButton} type="submit">
                          {
                            this.state.loadingSaveButton == true ?
                              <Spinner size={SpinnerSize.small} />
                              :
                              "Sauvegarder"
                          }
                        </PrimaryButton>
                      </div>
                      :
                      <React.Fragment />
                  }
                </form>
            }

            <Dialog
              hidden={this.state.deleteFDPModal.hideDialog}
              onDismiss={this.openCloseDeleteDialog}
              dialogContentProps={{
                type: DialogType.normal,
                title: "Supprimer la fiche de poste",
                closeButtonAriaLabel: 'Fermer',
                showCloseButton: !this.state.deleteFDPModal.loading
              }}
              modalProps={{
                isBlocking: true,
              }}
            >
              {
                this.state.deleteFDPModal.error == true ?
                  <p>{this.state.deleteFDPModal.errorMessage}</p>
                  :
                  this.state.deleteFDPModal.loading == true ?
                    <Spinner size={SpinnerSize.large} label="Suppression de la fiche de poste en cours..., redirection sur la liste des fomulaires" labelPosition="bottom" />
                    :
                    <React.Fragment>
                      <p>Voulez vous vraiment supprimer la fiche de poste associée au formulaire {this.state.formInfo.name} ?</p>
                      <DialogFooter>
                        <PrimaryButton onClick={this.deleteFDP} text="Supprimer" />
                        <DefaultButton onClick={this.openCloseDeleteDialog} text="Annuler" />
                      </DialogFooter>
                    </React.Fragment>
              }
            </Dialog>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(FicheDePoste);